#container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;
}

#banner {
  font-family: "Outfit", Helvetica, Arial, sans-serif;
  font-size: 15vw;
  font-weight: 700;
  font-optical-sizing: auto;

  /* word-spacing: 100vw; */
  line-height: 85%;
}

@media (prefers-color-scheme: dark) {
  html {
    background-color: black;
    color: white;
  }
}
